module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"oaktyresmainsite","accessToken":"MC5Ya0xieXhNQUFDQUFxWTlw.Qe-_ve-_ve-_vWzvv71kaQkh77-977-977-9GEfvv73vv73vv73vv73vv70g77-9OgQePAAmPATvv71w","path":"/preview","previews":false,"omitPrismicScript":true,"pages":[{"type":"Textual_page","match":"/:uid","path":"/textual-page","component":"/codebuild/output/src1342609159/src/oak-website/src/templates/textual-pages.tsx"},{"type":"News_article","match":"/news/:uid","path":"/article-preview","component":"/codebuild/output/src1342609159/src/oak-website/src/templates/article.tsx"},{"type":"Event_page","match":"/:uid","path":"/event-page","component":"/codebuild/output/src1342609159/src/oak-website/src/templates/event-page.tsx"}],"sharpKeys":[{},"company_logo","logo"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
